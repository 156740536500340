<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Transfer Egg Sale</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> List </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>

		<b-card no-body>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Date picker -->
					<b-col cols="12" md="6">
						<b-row>
							<b-col md="5">
								<label for="example-input">From Date</label>
								<b-form-datepicker
									id="from-date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.from_date"
								/>
							</b-col>
							<b-col md="5">
								<label for="example-input">To Date</label>
								<b-form-datepicker
									id="to-date"
									:date-format-options="{
										year: 'numeric',
										month: 'short',
										day: '2-digit',
										weekday: 'short',
									}"
									placeholder="Choose a date"
									local="en"
									v-model="search_filter.to_date"
								/>
							</b-col>
							<b-col md="2 d-flex align-items-end">
								<b-button @click="getTransferSale()" variant="primary "> Filter </b-button>
							</b-col>
						</b-row>
					</b-col>
					<!-- search -->
					<b-col cols="12" md="6" class="mt-2 d-flex justify-content-end align-items-center">
						<div>
							<b-form-input
								type="text"
								class="form-control"
								placeholder="Search"
								@input="getTransferSale()"
								v-model.trim="search_filter.input_field"
								style="border-radius: 4px"
							/>
						</div>
						<b-button
							@click="clearFilter"
							variant="secondary
 			 ml-1"
						>
							Clear
						</b-button>
						<b-button v-b-modal.Transfer-Egg-Sale variant="primary mr-1 ml-1"> Add </b-button>
						<b-button @click="excelDownload()" variant="warning">
							<feather-icon icon="DownloadIcon" size="16" />
						</b-button>
					</b-col>
				</b-row>
			</div>
			<div v-if="transferSale && transferSale.total > 0">
				<b-table
					:items="transferSale.data"
					responsive
					:fields="fields"
					show-empty
					empty-text="No matching records found"
					class="position-relative"
				>
					<template #cell(index)="data">
						<div class="ml-1">
							{{ transferSale.from + data.index }}
						</div>
					</template>
				</b-table>
				<div class="mx-2 mb-2">
					<b-pagination
						:value="transferSale.current_page"
						:total-rows="transferSale.total"
						:per-page="transferSale.per_page"
						align="right"
						@change="getTransferSale"
					>
					</b-pagination>
				</div>
			</div>
			<div v-else class="d-flex flex-column align-items-center py-5">
				<b-img :src="require('@/assets/images/svg/infographics/no_data_infographic.svg')" class="mr-2" />
				<h4 class="mt-2 Secondary">No transfer egg sale yet</h4>
			</div>
			<!-- hatch mortality modal -->
			<b-modal
				id="Transfer-Egg-Sale"
				title="Transfer Egg Sale"
				ok-only
				@ok="addTransferSale"
				ok-title="Submit"
				ref="Transfer-Egg-Sale"
			>
				<validation-observer ref="simpleRules">
					<form ref="form">
						<b-form-group>
							<template v-slot:label> Flock ID<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Flock ID" rules="required">
								<v-select
									v-model="FormData.flock_id"
									:clearable="false"
									label="flock_id"
									:options="flockDataList"
									:reduce="(e) => e.flock_id"
									@input="getFlockDetails"
								/>

								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<!-- <b-form-group>
							<template v-slot:label> Batch ID<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Batch ID" rules="required">
								<v-select
									v-model="selectedOtherSaleId"
									:clearable="false"
									label="batch_id"
									:options="hatchList"
									:required="!selectedOtherSaleId"
									:reduce="(e) => e.batch_id"
								/>

								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group> -->
						<b-form-group>
							<template v-slot:label> Total Eggs Available<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name="Total Eggs Available" rules="required">
								<b-form-input
									name="total_eggs_available"
									v-model="flock_details.usable_eggs"
									placeholder="Total Eggs Available"
									disabled
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group>
							<template v-slot:label>
								No. of eggs to transfer<span class="text-danger">*</span>
							</template>
							<validation-provider
								#default="{ errors }"
								name="No. of eggs to transfer"
								:rules="{
									required: true,
									max_value: flock_details.usable_eggs,
								}"
							>
								<b-form-input
									name="eggs_transfer"
									v-model="FormData.eggs_transfer"
									placeholder="No. of eggs to transfer"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<b-form-group>
							<template v-slot:label> Buyer<span class="text-danger">*</span> </template>
							<validation-provider #default="{ errors }" name=" Source" rules="required">
								<v-select
									v-model="FormData.source_id"
									:options="sourceList"
									label="name"
									clearable:false
									:reduce="(e) => e.id"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</form>
				</validation-observer>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useTransferSaleList from "./useTransferSaleList";
import saleStoreModules from "../saleStoreModules";
import moment from "moment";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BASE_URL } from "@core/common/constants";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
	components: {
		vSelect,
	},
	data() {
		return {
			required,
			transferSale: null,
			selected: null,
			flockDataList: [],
			total_eggs_available: null,
			eggs_transfer: null,
			buyer: null,
			selectedOtherSaleId: null,
			rows: null,
			search: null,
			flock_id: null,
			fields: [
				{ key: "index", label: "s.no" },
				{ key: "flock_id", label: "flock-id" },
				// { key: `total_eggs_available`, label: "total eggs available" },
				{ key: `egg_transferred`, label: "eggs transfer" },
				{ key: `source.name`, label: "buyer" },
				{
					key: "created_at",
					label: "sell date",
					formatter: (value) => {
						return moment(value).format("MMMM Do YYYY");
					},
				},
			],
			FormData: {
				flock_id: null,
				total_eggs_available: null,
				eggs_transfer: null,
				buyer: null,
			},
			search_filter: {
				input_field: "",
				to_date: "",
				from_date: "",
			},
			flock_details: [],
		};
	},
	created() {
		this.farmId = this.$route.params.farmId;
		this.getTransferSale();
		this.getFlockList();
		this.getSource();
	},
	methods: {
		getTransferSale(pageNo = 1) {
			const farmId = this.$route.params.farmId;
			const saleType = "transfer";
			let url = `web/farm/${farmId}/transfer-list`;
			let queryParams = {
				page: pageNo,
			};

			if (this.search_filter.input_field) {
				queryParams.input_field = this.search_filter.input_field;
			}
			if (this.search_filter.to_date) {
				queryParams.to_date = this.search_filter.to_date;
			}
			if (this.search_filter.from_date) {
				queryParams.from_date = this.search_filter.from_date;
			}

			axiosIns
				.get(this.addQueryString(url, queryParams))
				.then((response) => {
					this.transferSale = response.data.transfer_eggs_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getSource() {
			axiosIns
				.get(`web/get-all-sources`)
				.then((response) => {
					this.sourceList = response.data.source_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		addTransferSale(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					this.farmId = this.$route.params.farmId;
					const saleType = "transfer";
					var data = new FormData();
					let flock_id = this.FormData.flock_id;
					data.append("egg_transferred", this.FormData.eggs_transfer);
					data.append("source_id", this.FormData.source_id);
					data.append("sale_type", saleType);
					axiosIns
						.post(`web/farm/${this.farmId}/flock/${flock_id}/transfer-eggs`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$refs["Transfer-Egg-Sale"].hide();
							this.getTransferSale();
						})

						.catch((error) => {
							error.response;
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");
							arr, "array";
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
							this.getTransferSale();
						});
				}
			});
		},

		excelDownload() {
			const saleType = "transfer";
			if (this.search_filter.from_date && this.search_filter.to_date) {
				this.search_filter.from_date = moment(this.search_filter.from_date).format("YYYY-MM-DD");
				this.search_filter.to_date = moment(this.search_filter.to_date).format("YYYY-MM-DD");
			}
			let order_filter_data = this.prepareForm(this.search_filter);
			axiosIns
				.post(`web/export-post-transfer-egg/${saleType}`, order_filter_data)
				.then((response) => {
					var tempLink = document.createElement("a");
					tempLink.style.display = "none";
					tempLink.href = response.data.file;
					tempLink.setAttribute("download", response.data.name);
					if (typeof tempLink.download === "undefined") {
						tempLink.setAttribute("target", "_blank");
					}
					document.body.appendChild(tempLink);
					tempLink.click();
					document.body.removeChild(tempLink);
					this.getSourceList();
				})
				.catch((error) => {
					error.response;
					const data = error.response.data.errors;
					data, "data";
					let arr = Object.values(data).flat().toString(",");
					arr, "array";
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: arr,
						},
					});
					this.search_filter.to_date = null;
					this.search_filter.from_date = null;
					this.search_filter.input_field == null;
					this.getSourceList();
				});
		},
		getFlockList() {
			const farmId = this.$route.params.farmId;
			axiosIns
				.get(`farm/${farmId}/flock-list`)
				.then((response) => {
					this.flockDataList = response.data.flock_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getFlockDetails(flockId) {
			axiosIns
				.get(`web/farm/${this.farmId}/flock/${flockId}/info`)
				.then((response) => {
					this.flock_details = response.data.flock_details;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		clearFilter() {
			this.search_filter.input_field = "";
			this.search_filter.to_date = "";
			this.search_filter.from_date = "";
			this.getTransferSale();
		},
	},

	setup() {
		const INVOICE_APP_STORE_MODULE_NAME = "app-invoice";

		// Register module
		if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
			store.registerModule(INVOICE_APP_STORE_MODULE_NAME, saleStoreModules);

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
		});

		const statusOptions = ["Downloaded", "Draft", "Paid", "Partial Payment", "Past Due"];

		const {
			fetchInvoices,
			saleTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,
			statusFilter,
			refetchData,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		} = useTransferSaleList();

		return {
			fetchInvoices,
			saleTable,
			perPage,
			currentPage,
			totalInvoices,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refInvoiceListTable,
			statusFilter,
			refetchData,
			statusOptions,
			avatarText,
			resolveInvoiceStatusVariantAndIcon,
			resolveClientAvatarVariant,
		};
	},
};
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
.header-title {
	height: 60px;
	border-bottom: 1px solid #ebe9f1;
	font-weight: 500;
	color: #636363;
	font-size: 1.714rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem;
}

.invoice-filter-select {
	min-width: 190px;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
